import React from 'react';

import Seo from '../components/Seo';

import sections from '../constants/sections';

function LorealContentPro() {
  return <Seo title={sections.LorealContentPro.title} />;
}

export default LorealContentPro;
